<template>

  <div class="flex flex-col h-full text-primary mx-auto relative text-base">
    <div class="max-w-[1920px] mx-auto w-full">
      <Menu></Menu>

      <div v-if="useLayout().goBack && useLayout().header"
           @click="useRouter().back();document.getElementById('main-content').scrollTop=0"
           class="flex items-center gap-4 p-4">
        <i class="fa fa-angle-left"></i>
        <div>{{ t(useLayout().header as String) }}</div>
      </div>

      <div v-else-if="useLayout().header" @click="goTo(useLayout().previousStep)" class="flex items-center gap-4 p-4">
        <i class="fa fa-angle-left"></i>
        <div>{{ t(useLayout().header as String) }}</div>
      </div>
      <div v-if="useLayout().step" class="h-1 bg-contrast transition-all"
           :style="`width:${Number(useLayout().step / useFlow().currentFlow.steps) * 100}%`"></div>
    </div>
    <div class="flex-1 overflow-auto " :class="useLayout().colored?'bg-light':''">
      <div id="main-content" class="mx-auto w-full max-w-[1920px] h-full flex-1 overflow-y-auto flex flex-col relative">
        <div class="fixed bottom-2 right-2 z-10 flex flex-col gap-2">
          <TransitionGroup>
            <div v-for="notification in useNotifications().notifications">
              <div class="bg-green-500 px-4 py-2 rounded-full text-white flex gap-2 items-center"
                   :class="[notification.color]">
                <i class="fal" :class="[notification.icon]"></i>
                {{ notification.value }}
              </div>
            </div>
          </TransitionGroup>
        </div>
        <slot></slot>
      </div>
    </div>

    <slot name="footer"></slot>

    <div v-if="useLayout().footer" class="max-w-[1920px] mx-auto w-full">
      <div class="p-4 flex justify-between">
        <div @click="goTo(useLayout().previousStep)">
          <Button bg-class="bg-white" border-classes="border-2 border-primary" text-color-class="text-primary">
            {{ t('previousStep') }}
          </Button>
        </div>
        <Button @click="goToNextStep">{{ t('nextStep') }}</Button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {useNotifications} from "~/stores/notifications";
import {useFlow} from "~/stores/flow";
import {useLayout} from "~/stores/layout";

const {t, lang} = useLanguage()

const goToNextStep = async () => {
  if (useLayout().nextStepCheck) {

    if (await useLayout().nextStepCheck()) {
      document.getElementById('main-content').scrollTop = 0
      navigateTo(useLayout().nextStep as String)
    }
  } else if (useLayout().nextStep) {
    navigateTo(useLayout().nextStep as String)
    document.getElementById('main-content').scrollTop = 0
  }
}

const goTo = (to) => {
  let options = {}
  if(to === useRuntimeConfig().public.siteUrl[lang.value])
  {
    options = {external:true}
  }
  navigateTo(to, options)
  document.getElementById('main-content').scrollTop = 0
}

</script>
